@mixin clearfix {
    &::after {
        content: "";
        display: block;
        clear: both;
    }
}
@mixin media($breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}
@mixin formStyle {
	width: 80%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    background-color: white;
    font-size: 16px;
    line-height: 1.4;
    -webkit-appearance: none;
    &::placeholder {
    	color: #D9DBDD;
    }
    @include media(l) {
    	width: 100%;
    }
}