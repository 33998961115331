// 色関係の設定 --------------------
    // サイトカラー
    $main-color: #F2F2EB;
    $sub-color: #D0D2A5;
    $btn-color: #555652;
    $accent-color: #B40019;
    //フォントカラー
    $font-color: #333333;

// ブレイクポイント --------------------
    $breakpoints: (
		s: "only screen and (max-width: 575px)",
        m: "only screen and (max-width: 768px)",
        l: "only screen and (max-width: 991px)",
        xl: "only screen and (min-width: 992px)",
    );

// フォントの読み込み --------------------
@font-face {
    font-family: 'MyFont';
    src: url(font/BROCBRT0.ttf);
}