// 共通スタイル --------------------
header {
	@include clearfix;
	.pattern {
		margin-bottom: 56px;
		@include media(l) {
			margin-bottom: 20px;
		}
	}
	.logo-wrapper {
		.logo a {
			display: block;
			width: 140px;
			height: 136.78px;
			@include media(l) {
				width: 100px;
				height: auto;
				margin: 0 auto;
			}
			@include media(s) {
				width: 80px;
			}
		}
		@include media(xl) {
			float: left;
			width: 20%;
			padding-left: 100px;
		}
	}
	nav {
		.menu {
			margin-top: 48px;
			text-align: right;
			@include media(l) {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				margin-top: 0;
				padding: 80px 0;
				background: rgba(255, 255, 255, .8);
				z-index: 999;
			}
			li {
				@include media(xl) {
					display: inline-block;
					margin-right: 32px;
					&:last-child {
						margin-right: 0;
					}
				}
			}
			a {
				@include media(l) {
					display: block;
					padding: 14px 0 14px;
					font-size: 13px;
					color: $font-color;
					text-align: center;
					font-weight: bold;
				}
			}
			@include media(l) {
				display: none;
			}
		}
		.slide-menu {
			position: absolute;
			top: 80px;
			right: 24px;
			z-index: 15;
			display: none;
			@include media(l) {
				display: block;
			}
		}
		.open {
			z-index: 1000;
		}
		@include media(xl) {
			float: right;
			width: 80%;
			padding-right: 100px;
		}
	}
	@include media(l) {
		margin-bottom: 20px;
	}
}

footer {
	border-top: 1px solid #EEEEEE;
	.footer-inner {
		@include clearfix;
		padding: 40px 108px;
		.footer-logo {
			float: left;
			width: 140px;
			height: auto;
			@include media(s) {
				float: none;
				width: 100px;
				margin: 36px auto 20px;
			}
		}
		.footer-nav {
			float: right;
			width: 220px;
			ul {
				@include clearfix;
				padding-top: 28px;
				li {
					float: left;
				}
				@include media(m) {
					padding-top: 16px;
				}
				@include media(s) {
					padding-bottom: 20px;
				}
			}
			@include media(s) {
				float: none;
				margin: 0 auto;
			}
		}
		@include media(m) {
			padding: 32px 30px;
		}
		@include media(s) {
			padding: 0;
		}
	}
	.scroll-up {
		display: none;
		position: fixed;
		right: 40px;
		bottom: 20px;
		z-index: 500;
		@include media(s) {
			right: 20px;
			width: 48px;
			height: auto;
		}
	}
}


// トップページ ここから
#mainvisual {
	position: relative;
	margin-bottom: 120px;
	.image-slider {
		position: relative;
		width: 100%;
		z-index: 100;
		img {
			float: right;
			width: 74%;
			@include media(m) {
				float: none;
				width: 100%;
			}
		}
		.sp {
			display: none;
			@include media(m) {
				display: block;
			}
		}
		.pc {
			@include media(m) {
				display: none;
			}
		}
		.flex-control-nav {
			width: 500px;
			position: absolute;
			bottom: 10%;
			left: 9.5%;
			text-align: left;
			z-index: 500;
			@media screen and (max-width:1240px) {
				display: none;
			}
			@include media(m) {
				display: block;
				width: 100%;
				bottom: -48px;
				left: auto;
				text-align: center;
			}
			@include media(s) {
				bottom: -40px;
			}
		}
		@include media(m) {
			margin-bottom: 24px;
		}
	}
	.mainvisual-copy {
		position: absolute;
		top: 21%;
		left: 10%;
		margin: 0;
		padding: 0;
		z-index: 500;
		@include media(l) {
			left: 8%;
		}
		@include media(m) {
			position: relative;
			top: auto;
			left: auto;
			padding: 8% 30px 0;
			z-index: 500;
		}
	}
	@include media(l) {
		margin-bottom: 56px;
	}
	@include media(s) {
		margin-bottom: 40px;
	}
}

#recommend {
	width: 100%;
	padding: 0 8% 120px;
	.item-list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include media(l) {
			flex-wrap: wrap;
		}
		li {
			@include media(xl) {
				padding-right: 40px;
			}
			&:last-child {
				@include media(l) {
					padding-right: 0;
				}
			}
			@include media(l) {
				width: 48%;
				margin-bottom: 40px;
			}
		}
	}
	@include media(l) {
		padding-bottom: 16px;
	}
}

#news {
	padding: 0 20% 120px;
	h2.title {
		margin-bottom: 24px;
		@include media(l) {
			margin-bottom: 8px;
		}
	}
	@include media(l) {
		padding: 0 30px 56px;
	}
}

#features {
	margin-bottom: 120px;
	.feature-box {
		@include clearfix;
		margin-bottom: 100px;
		&:nth-of-type(odd) {
			.image-side {
				float: left;
				width: 51%;
				margin-bottom: 0;
				@include media(m) {
					float: none;
					width: 100%;
					margin-bottom: 24px;
				}
			}
			.text-side {
				float: right;
				width: 49%;
				padding: 4% 7% 0;
				@include media(l) {
					padding-top: 0;
				}
				@include media(m) {
					float: none;
					width: 100%;
					padding: 0;
					padding: 0 30px;
				}
			}
		}
		&:nth-of-type(even) {
			.image-side {
				float: right;
				width: 51%;
				margin-bottom: 0;
				@include media(m) {
					float: none;
					width: 100%;
					margin-bottom: 24px;
				}
			}
			.text-side {
				float: left;
				width: 49%;
				padding: 4% 7% 0;
				@include media(l) {
					padding-top: 0;
				}
				@include media(m) {
					float: none;
					width: 100%;
					padding: 0 30px;
				}
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
		@include media(m) {
			margin-bottom: 48px;
		}
	}
	@include media(l) {
		margin-bottom: 56px;
	}
}

#shopping {
	margin: 0 auto;
	margin-bottom: 120px;
	@include media(xl) {
		max-width: 560px;
		min-width: 560px;
	}
	@include media(l) {
		margin-bottom: 56px;
	}
}

#instagram {
	padding: 80px 8% 120px;
	background: $main-color;
	@include media(l) {
		padding: 56px 8% 80px;
	}
	@include media(m) {
		padding: 48px 30px 56px;
	}
}

#content {
	margin-bottom: 120px;
	// お問い合わせ ここから
	.aside-message {
		width: 945px;
		margin: 0 auto 56px;
		@include media(l) {
			width: 750px;
		}
		@include media(m) {
			width: 100%;
		}
		@include media(s) {
			margin-bottom: 32px;
		}
	}
	.panel {
		width: 945px;
		margin: 0 auto;
		border: 1px solid #eee;
		border-top: 3px solid $font-color;
		border-radius: 2px;
		.inquiry {
			width: 100%;
			th {
				width: 25%;
				text-align: right;
				@include media(l) {
					float: left;
					width: 100%;
					text-align: left;
				}
			}
			td {
				width: 50%;
				@include media(l) {
					float: left;
					width: 100%;
				}
			}
		}
		@include media(l) {
			width: 750px;
		}
		@include media(m) {
			width: 100%;
		}
	}
	@include media(l) {
		margin-bottom: 80px;
	}
	@include media(m) {
		padding: 0 24px;
	}
}
