// 共通スタイル
header {
	nav .btn {
		@include media(xl) {
			position: relative;
			display: inline-block;
			padding: 17px 40px 17px 76px;
			border-radius: 3px;
			font-size: 14px;
		}
		@include media(l) {
			background: none;
		}
		&:before {
			@include media(xl) {
				content: "\f0e0";
				font-family: "FontAwesome";
				position: absolute;
				left: 32px;
				top: 14px;
				font-size: 18px;
			}
		}
	}
	.menu-trigger {
		display: inline-block;
		position: relative;
		width: 28px;
		height: 22px;
		span {
			display: inline-block;
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: #000000;
			border-radius: 4px;
			transition: ease .4s;
			&:nth-of-type(1) {
				top: 0;
			}
			&:nth-of-type(2) {
				top: 10px;
			}
			&:nth-of-type(3) {
				bottom: 0;
			}
		}
	}
	.open {
		span {
			&:nth-of-type(1) {
				transform: translateY(10px) rotate(-45deg);
			}
			&:nth-of-type(2) {
				left: 50%;
				opacity: 0;
				animation: active-menu-bar02 .8s forwards;
			}
			&:nth-of-type(3) {
				transform: translateY(-10px) rotate(45deg);
			}
		}
	}
}

footer {
	.footer-nav {
		li {
			margin-bottom: 1.6em;
			font-size: 12px;
			font-weight: bold;
			line-height: 1;
			&:nth-of-type(odd) {
				padding-right: 40px;
			}
		}
		#pd-narrow {
			padding-right: 27px;
		}
	}
	.scroll-up {
		img {
			width: 100%;
		}
	}
	.copy {
		margin-bottom: 16px;
		font-size: 12px;
		text-align: center;
	}
}


// トップページ ここから
#mainvisual {
	.mainvisual-copy {
		&:after {
			content: "";
			display: inline-block;
			width: 20px;
			height: 1px;
			background-color: $font-color;
			vertical-align: middle;
		}
		.main {
			margin: 0 0 64px -3px;
			font-size: 38px;
			font-weight: bold;
			line-height: 1.7;
			letter-spacing: 2px;
			br {
				@include media(m) {
					display: none;
				}
			}
			@include media(m) {
				margin-bottom: 24px;
				font-size: 22px;
			}
		}
		.sub {
			width: 72%;
			margin-bottom: 64px;
			@include media(m) {
				width: 100%;
				margin-bottom: 16px;
				font-size: 13px;
			}
		}
	}
}

#recommend {
	h2.title {
		margin-bottom: 40px;
	}
	.item-list {
		.imgarea {
			margin-bottom: 16px;
			a:hover {
				opacity: .85;
				transition: ease .5s;
			}
			img {
				width: 100%;
				height: auto;
				border-radius: 6px;
			}
		}
		.textarea {
			a:hover {
				color: $accent-color;
				transition: ease .2s;
			}
		}
	}
}

#news {
	li {
		border-bottom: 1px solid $sub-color;
		a {
			display: block;
			padding: 16px 0;
			text-decoration: none;
			transition: all .2s;
			.news-category {
				display: inline-block;
				width: 110px;
				padding: 6px 0;
				background-color: $sub-color;
				border-radius: 3px;
				text-align: center;
				font-size: 13px;
				color: #fff;
				@include media(m) {
					width: 90px;
					padding: 2px 0 1px;
					font-size: 12px;
				}
			}
			.news-date {
				display: inline-block;
				width: 114px;
				text-align: center;
				@include media(m) {
					width: 70px;
					padding-left: 4px;
					font-size: 12px;
				}
			}
			.news-title {
				display: inline-block;
				text-align: left;
				@include media(m) {
					display: block;
					width: 100%;
					margin-top: 12px;
				}
			}
		}
	}
}

#features {
	.image-side {
		img {
			width: 100%;
		}
	}
	.text-side {
		h3 {
			margin-bottom: 40px;
			@include media(m) {
				margin-bottom: 16px;
			}
		}
		.en {
			display: block;
			margin-bottom: 24px;
			font-size: 18px;
			color: $accent-color;
			@include media(m) {
				margin-bottom: 8px;
				font-size: 14px;
			}
		}
		.jp {
			display: block;
			font-size: 28px;
			font-weight: bold;
			line-height: 1.35;
			br {
				@include media(l) {
					display: none;
				}
			}
			@include media(m) {
				font-size: 22px;
			}
		}
	}
}

#shopping {
	.icon {
		margin-bottom: 32px;
		text-align: center;
		img {
			width: auto;
			height: 65px;
			@include media(m) {
				height: 45px;
			}
		}
		@include media(m) {
			margin-bottom: 20px;
		}
	}
	h2.title {
		margin-bottom: 16px;
		@include media(m) {
			margin-bottom: 12px;
		}
	}
	p {
		margin-bottom: 24px;
		text-align: center;
	}
	.btn {
		position: relative;
		display: block;
		padding: 20px;
		border-radius: 6px;
		font-size: 18px;
		text-align: center;
		@include media(l) {
			width: 48%;
			margin: 0 auto;
		}
		@include media(m) {
			width: 84%;
			padding: 16px;
			font-size: 15px;
		}
	}
}

#instagram {
	p {
		margin-top: 8px;
		margin-bottom: 40px;
		line-height: 1;
		text-align: center;
		@include media(s) {
			margin-bottom: 32px;
		}
	}
	.gray {
		width: 100%;
		height: 34vw;
		background: white;
	}
}

#content {
	// お問い合わせ ここから
	h2 {
		position: relative;
		margin: 40px 0 56px;
		font-size: 52px;
		font-weight: bold;
		text-align: center;
		&:before {
			content: "";
			position: absolute;
			left: 50%;
			bottom: 0;
			margin-left: -16px;
			width: 40px;
			border-bottom: 2px solid $font-color;
			@include media(m) {
				width: 32px;
			}
		}
		@include media(l) {
			font-size: 48px;
		}
		@include media(m) {
			margin: 32px 0;
			font-size: 32px;
		}
	}
	.tel {
		color: #1f3744;
		text-decoration: underline;
	}
	.panel {
		.panel-heading {
			padding: 24px 32px;
			border-bottom: 1px solid #eee;
			h4 {
				font-size: 20px;
				font-weight: bold;
				line-height: 1;
				@include media(m) {
					font-size: 18px;
				}
			}
			@include media(l) {
				padding: 16px 20px;
			}
			@include media(m) {
				padding: 16px 16px;
			}
		}
		.haveto {
			color: $accent-color;
		}
		.privacy-policy {
			display: block;
			text-decoration: underline;
		}
		.screen-reader-response {
			display: none;
		}
		.wpcf7-validation-errors {
			margin-top: 40px;
			border: 2px solid #f7e700;
			text-align: center;
			@include media(s) {
				font-size: 12px;
			}
		}
		.wpcf7-mail-sent-ok {
			margin-top: 40px;
			border: 2px solid $accent-color;
			text-align: center;
			@include media(s) {
				font-size: 12px;
			}
		}
		.wpcf7-checkbox .wpcf7-list-item {
			display: block;
			width: 100%;
			margin-bottom: 8px;
			margin-left: 0;
			font-size: 16px;
			color: #7F878C;
			cursor: pointer;
			.wpcf7-list-item-label {
				position:relative;
				padding-top: 2px;
				padding-left: 2em;
				vertical-align: middle;
				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 20px;
					height: 20px;
					border: 1px solid #C9C9C9;
				}
			}
			input[type="checkbox"]:checked + .wpcf7-list-item-label{
				color: $accent-color;
				&:after {
					content: "";
					display: block;
					position: absolute;
					top: -2px;
					left: 5px;
					width: 8px;
					height: 16px;
					transform: rotate(40deg);
					border-bottom: 3px solid $accent-color;
					border-right: 3px solid $accent-color;
				}
			}
		}
		.inquiry {
			th, td {
				border-bottom: 1px solid #EEEEEE;
				font-size: 16px;
				@include media(l) {
					border-bottom: none;
				}
				@include media(m) {
					font-size: 14px;
				}
			}
			th {
				padding: 20px;
				font-weight: bold;
				color: #7F878C;
				@include media(l) {
					padding-bottom: 0;
				}
				@include media(m) {
					padding: 10px 16px 0;
				}
			}
			th.message {
				vertical-align: top;
				@include media(xl) {
					padding-top: 32px;
				}
			}
			td {
				padding: 20px;
				@include media(l) {
					padding-top: 0;
				}
				@include media(m) {
					padding: 0 16px 10px;
				}
			}
			input[type="text"],
	        input[type="tel"],
	        input[type="email"],
	        input[type="number"] {
	        	@include formStyle;
	        	height: 54px;
	        }
	        input[type="checkbox"] {
	        	display: none;
	        }
	        textarea {
	        	@include formStyle;
	        	height: 200px;
	        	padding-top: 10px;
	        }
	        .wpcf7-not-valid-tip {
				font-size: 13px;
				color: red;
			}
		}
		p {
			padding: 20px;
			text-align: center;
			input[type="submit"] {
	            width: 216px;
	            height: 54px;
	            background: #555652;
				border-radius: 4px;
	            color: white;
	            font-size: 16px;
	            line-height: 54px;
	            text-align: center;
	            -webkit-appearance: none;
	            cursor: pointer;
	            @include media(s) {
	            	font-size: 14px;
	            }
	        }
	        @include media(l) {
	        	border-top: 1px solid #EEEEEE;
	        }
		}
	}
}