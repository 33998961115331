* {
	box-sizing: border-box;
}

html {
	overflow-y: scroll;
}

body {
    font-family: 'MyFont', YuGothic, '游ゴシック', sans-serif;
    color: $font-color;
    font-size: 15px;
	line-height: 1.73;
	@include media(m) {
		font-size: 14px;
	}
}

img {
	vertical-align: bottom;
	border-style: none;
	overflow-x: hidden;
}

a {
	color: $font-color;
	text-decoration: none;
}

.pattern {
	width: 100%;
	height: 56px;
	background-image: url(images/min/pattern_pc.png);
	background-repeat: repeat-x;
	background-size: 125px 56px;
	@include media(l) {
		height: 42px;
		background-image: url(images/min/pattern_pc.png);
		background-size: 93.75px 42px;
	}
}

h2.title {
	text-align: center;
	img {
		width: auto;
		height: 58px;
		@include media(m) {
			height: 40px;
		}
	}
}

.btn {
	background: $btn-color;
	line-height: 1;
	color: white;
}

// 404エラー
#error {
	display: flex;
	justify-content: space-between;
	width: 600px;
	margin: 40px auto 0;
	color: white;
	text-align: center;
	.inner {
		width: 100%;
		margin: 0 auto;
		padding: 80px 0;
		background: $sub-color;
		border-radius: 6px;
		.icon {
			margin-bottom: 24px;
			font-size: 5em;
			line-height: 1;
		}
		h1 {
			font-size: 24px;
		}
	}
	@include media(m) {
		width: 85%;
		margin-top: 8%;
	}
}

// ローディングアニメーション
.loader{
	position:fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	z-index:9999;
}

.loader-animation {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 8em;
	height: 8em;
	margin: auto;
	border-radius: 50%;
	background: $sub-color;
	background: -moz-linear-gradient(left, $sub-color 10%, rgba(65, 100, 148, 0) 42%);
	background: -webkit-linear-gradient(left, $sub-color 10%, rgba(65, 100, 148, 0) 42%);
	background: -o-linear-gradient(left, $sub-color 10%, rgba(65, 100, 148, 0) 42%);
	background: -ms-linear-gradient(left, $sub-color 10%, rgba(65, 100, 148, 0) 42%);
	background: linear-gradient(to right, $sub-color 10%, rgba(65, 100, 148, 0) 42%);
	font-size: 10px;
	text-indent: -9999em;
	-webkit-animation: load3 1.4s infinite linear;
	animation: load3 1.4s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}

.loader-animation:before {
  width: 50%;
  height: 50%;
  background: $sub-color;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.loader-animation:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}